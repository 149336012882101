import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initialRequest, saveAnswerRequest, isLoadingStatus, updateCountDownStatus } from '../../Containers/QuestionnaireContainer/action';
import { Translate } from 'react-localize-redux';
import * as webConst from '../../utils/WebConstant';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import './Questionnaire.css';
import ContentLoader from 'react-content-loader';
import Countdown from 'react-countdown';

class Questionnaire extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "step": 1,
      "max": 10,
      "min": 0,
      "activationCode": "",
      "questionnaires": [],
      "defaultState": "",
      "question_id": [],
      'isLoading': props.isLoading
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
  }

  componentDidMount = () => {

    let queryParam = this.props.match.params.clientcode ? this.props.match.params.clientcode : this.props.location.search;
    if (queryParam) {
      this.getUrlFromCode(queryParam);
    }
    else {
      this.props.isLoadingStatus(false);
      this.props.history.push('/404');
      webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.valid-link"></Translate>);
    }
  }

  componentDidUpdate = prevProps => {
    const { clientSuccess, clientError } = this.props;
    if (clientSuccess) {
      if (prevProps.clientSuccess !== this.props.clientSuccess) {
        this.props.isLoadingStatus(false);
      }
    }
    if (clientError) {
      if (prevProps.clientError !== this.props.clientError) {
        this.props.isLoadingStatus(false);
      }
    }
  }

  getUrlFromCode(activationCode) {
    this.props.initialRequest(activationCode);
  }

  handleChange = (item) => evt => {
    var answer = {
      ans: evt.target.value,
      qst_id: item.qst_id,
      qnr_id: item.qnr_id,
      ans_type: item.type
    };

    this.setState({ 'answer': answer });

    this.props.questionnaireData[evt.target.name]['qst_ans'] = evt.target.value;

    let question_id = this.state.question_id;

    if (question_id.indexOf(evt.target.name) === -1)
      question_id.push(evt.target.name);

    this.setState({ 'question_id': question_id });
  };

  handleSliderChange = (qst_id) => event => {
    this.setState({
      qst_id: event.target.value
    })
    this.props.questionnaireData[qst_id]['qst_ans'] = event.target.value;

    let question_id = this.state.question_id;
    if (question_id.indexOf(qst_id) === -1)
      question_id.push(qst_id);
    this.setState({ 'question_id': question_id });
  };

  handleSaveAnswers = (event) => {
    event.preventDefault();
    this.props.saveAnswerRequest(this.props.questionnaireData, this.props.clientData.clientId, this.props);
  }

  disableAnswer = () => {
    this.props.updateCountDownStatus(false);
  }

  render() {

    let { isQuestionnaireSuccess, questionnaireError, questionnaireData, isLoading, isEdited, expireEditAnswerTime, countDownStatus, editAnswerTimeHour } = this.props;
    countDownStatus = countDownStatus ? countDownStatus : false;
    editAnswerTimeHour = editAnswerTimeHour ? editAnswerTimeHour : 6;
    var today = new Date();
    expireEditAnswerTime = expireEditAnswerTime ? expireEditAnswerTime : today.setHours(today.getHours() + editAnswerTimeHour);
    if(typeof expireEditAnswerTime==='string')
      expireEditAnswerTime=expireEditAnswerTime.replace(" ","T");
    
    let listItem = "";
    if (Object.values(questionnaireData).length) {
      let self = this;
      listItem = Object.values(questionnaireData).map(function (item, index) {
        if (item.type === '1') {
          return (
            <li className="list-group-item text-left" key={index}><strong>{index + 1}. {item.qst}</strong>
              {questionnaireData[item.qst_id] && questionnaireData[item.qst_id]['qst_ans'] === "" ? 
                <div>
                  <input type="radio" onChange={self.handleChange(item)} name={item.qst_id} id={item.qst_id + "yes"} value={"yes"} checked="true" /> &nbsp; <label className="label" for={item.qst_id + "yes"}><Translate id="yes"></Translate></label>
                  <br/><input type="radio" onChange={self.handleChange(item)} name={item.qst_id} id={item.qst_id + "no"} value={"no"} /> &nbsp; <label className="label" for={item.qst_id + "no"}><Translate id="no"></Translate></label>
                </div> 
              : ""}
              {questionnaireData[item.qst_id] && questionnaireData[item.qst_id]['qst_ans'] !== "" ? 
                <div>
                  <input type="radio" onChange={self.handleChange(item)} name={item.qst_id} id={item.qst_id + "yes"} value={"yes"} checked={questionnaireData[item.qst_id]["qst_ans"] === "yes" ? true : false} /> &nbsp; <label className="label" for={item.qst_id + "yes"}><Translate id="yes"></Translate></label>
                  <br/><input type="radio" onChange={self.handleChange(item)} name={item.qst_id} id={item.qst_id + "no"} value={"no"} checked={questionnaireData[item.qst_id]["qst_ans"] === "no" ? true : false} /> &nbsp; <label className="label" for={item.qst_id + "no"}><Translate id="no"></Translate></label>
                </div> 
              : ""}
            </li>
          )
        }
        else if (item.type === '2') {
          return (
            <li className="list-group-item text-left" key={index}><strong>{index + 1}. {item.qst}</strong>
              <p><textarea className="form-control" disabled={isEdited} onChange={self.handleChange(item)} name={item.qst_id} value={questionnaireData[item.qst_id] ? questionnaireData[item.qst_id]['qst_ans'] : this.state.defaultState}></textarea></p>
            </li>
          )
        }
        else {
          return (
            <li className="list-group-item text-left" key={index}><strong>{index + 1}. {item.qst}</strong>
              <ReactBootstrapSlider
                value={questionnaireData[item.qst_id] ? Number(questionnaireData[item.qst_id]['qst_ans']) : 0}
                name={item.qst_id}
                change={self.handleSliderChange(item.qst_id)}
                slideStop={self.handleSliderChange(item.qst_id)}
                step={self.state.step}
                max={self.state.max}
                min={self.state.min}
                orientation="horizontal"
                reversed={false}
                ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                ticks_labels={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                disabled={isEdited === true ? "disabled" : ""}
                ticks_snap_bounds={questionnaireData[item.qst_id] ? questionnaireData[item.qst_id]['qst_ans'] : this.state.defaultState}
              />
            </li>
          )
        }
      })

    }
    return (
      <div className="mb-5">
        <div>
          {questionnaireError && <div className="alert alert-danger"><Translate id={questionnaireError.message}></Translate></div>}
        </div>
        {/* Qusetion list */}
        {
          (isLoading
            ?
            <ContentLoader height="278">
              <rect x="0" y="0" rx="4" ry="4" width="400" height="26" />
              <rect x="0" y="28" rx="4" ry="4" width="400" height="26" />
              <rect x="0" y="56" rx="4" ry="4" width="400" height="26" />
              <rect x="0" y="84" rx="4" ry="4" width="400" height="26" />
              <rect x="0" y="112" rx="4" ry="4" width="400" height="26" />
              <rect x="0" y="140" rx="4" ry="4" width="400" height="26" />
              <rect x="0" y="168" rx="4" ry="4" width="400" height="26" />
              <rect x="0" y="196" rx="4" ry="4" width="400" height="26" />
              <rect x="0" y="224" rx="4" ry="4" width="400" height="26" />
              <rect x="0" y="252" rx="4" ry="4" width="400" height="26" />
            </ContentLoader>
            :
            <form onSubmit={this.handleSaveAnswers}>
              {countDownStatus ? <p><Translate id="home.lock-answer-time" /> <strong className="text-danger"><Countdown daysInHours={true} date={expireEditAnswerTime} overtime={false} onComplete={this.disableAnswer} /></strong></p> : ''}
              <ul className="list-group">
                {listItem}
              </ul>
              {(isQuestionnaireSuccess && Object.values(questionnaireData).length > 0) && <button type="submit" className="btn btn-app btn-block fixed-bottom mt-10"><Translate id="Save"></Translate></button>}
            </form>
          )
        }
        {(isQuestionnaireSuccess && Object.values(questionnaireData).length === 0) && <div className="list-group-item"><Translate id="alert-message.no-questionnaires"></Translate></div>}
        {/* Qusetion list */}

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isQuestionnaireSuccess: state.questionnaireReducer.isQuestionnaireSuccess,
    questionnaireError: state.questionnaireReducer.questionnaireError,
    questionnaireData: state.questionnaireReducer.questionnaireData,
    clientData: state.appLayoutReducer.clientData,
    isEdited: state.appLayoutReducer.editedStatus,
    expireEditAnswerTime: state.appLayoutReducer.time,
    editAnswerTimeHour: state.appLayoutReducer.hour,
    isLoading: state.questionnaireReducer.isLoading,
    countDownStatus: state.questionnaireReducer.countDownStatus
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    initialRequest: (item) => dispatch(initialRequest(item)),
    saveAnswerRequest: (data, clientId) => dispatch(saveAnswerRequest(data, clientId)),
    isLoadingStatus: (isLoading) => dispatch(isLoadingStatus(isLoading)),
    updateCountDownStatus: (countDownStatus) => dispatch(updateCountDownStatus(countDownStatus))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);

