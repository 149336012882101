import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createReducer from './reducers';


export default function configureStore() {
    const store = createStore(createReducer,
        applyMiddleware(thunk)
        // applyMiddleware(thunk, logger)
    );
    return store;
}
