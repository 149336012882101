import { SET_CLIENT_DATA, SET_API_URL, SET_FEEDBACK_URL, SET_CLINIC_NAME, SET_CLINIC_DESCRIPTION,SET_LOGO,SET_EDITED_STATUS,SET_DISABLE_ANSWER_TIME,SET_ANSWER_HOUR } from './constants';



export function setClientData(clientData) {
    return {
        type: SET_CLIENT_DATA,
        clientData
    };
}

export function setApiUrl(apiUrl) {
    return {
        type: SET_API_URL,
        apiUrl
    };
}

export function setFeedbackUrl(feedbackUrl) {
    return {
        type: SET_FEEDBACK_URL,
        feedbackUrl
    };
}

export function setClinicName(name) {
    return {
        type: SET_CLINIC_NAME,
        name
    };
}

export function setClinicDescription(description) {
    return {
        type: SET_CLINIC_DESCRIPTION,
        description
    };
}

export function setLogo(logo) {
    return {
        type: SET_LOGO,
        logo
    };
}


export function setEditedStatus(status){
    return {
        type: SET_EDITED_STATUS,
        status
    };
}

export function setDisableAnswerTime(time){
    return {
        type:SET_DISABLE_ANSWER_TIME,
        time
    }
}

export function setEditAnswerHour(hour){
    return {
        type:SET_ANSWER_HOUR,
        hour
    }
}