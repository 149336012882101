import React, { Component } from 'react';
import './NotFound.css';
import { Translate } from 'react-localize-redux';

class NotFound extends Component {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  render() {
    return (
      <div>
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h2>4<span>0</span>4</h2>
            </div>
            <h2 className="text-danger"><Translate id="alert-message.link-expired" /></h2>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;