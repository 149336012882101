// local  environment
import * as webConst from '../utils/WebConstant';

const baseURLLocal = webConst.SERVER_URL;

export const withoutHeader = {
    baseURL: baseURLLocal,
    timeOut: 30000
};

export const withHeader = {
    baseURL: baseURLLocal,
    headers: {
        Accept: 'application/json',
        Authorization: ''
    },
    timeOut: 30000
};