/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import appLayoutReducer from '../Containers/AppLayoutContainer/reducer';
// import contactReducer from '../Containers/ContactContainer/reducer';
import questionnaireReducer from '../Containers/QuestionnaireContainer/reducer';
// import homeReducer from '../Containers/HomeContainer/reducer';

const createReducer = combineReducers({
    appLayoutReducer,
    // homeReducer,
    // contactReducer,
    questionnaireReducer,
    localize: localizeReducer
});

export default createReducer;
