import { toast } from 'react-toastify';

export const SERVER_URL = process.env.REACT_APP_HOST_ENV === 'local' ? process.env.REACT_APP_BASE_SERVER_LOCAL : process.env.REACT_APP_BASE_SERVER_STAGING;
export const REACT_APP_BASE_SERVER_PRODUCTION = process.env.REACT_APP_BASE_SERVER_PRODUCTION;
export const BASE_URL = process.env.BASE_URL;
export const GET_QUESTIONS = 'questions/index.php';
export const SAVE_ANSWER = 'save_answer/index.php';
export const CLIENT_CODE_VALIDATE = 'client_activation_code_validate/index.php';
export const CLIENT_LINK_EXPIRY = 'activation/index.php';


// Toater here 
export const TOAST_SUCCESS = 'success';
export const TOAST_ERROR = 'error';

export const toaster = (toastType, message) => {
    if (toastType === TOAST_SUCCESS) {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
        });
    } else if (toastType === TOAST_ERROR) {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
        });
    }
};