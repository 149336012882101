import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import englishTranslation from './utils/locales/en.json';
import danishTranslation from './utils/locales/da.json';
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import AppLayout from './Components/AppLayout';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      "clientCode": localStorage.getItem('client-code'),
      "en":true,
      "da":false,
    };

    this.props.initialize({
      languages: [
        { name: 'Denish', code: 'da' },
        { name: 'English', code: 'en' }
      ],
      translation: {},
      options: { renderToStaticMarkup }
    });

    this.props.addTranslationForLanguage(englishTranslation, 'en');
    this.props.addTranslationForLanguage(danishTranslation, 'da');
  }

  render() {
    return (
      <div>
        <AppLayout/>
        <ToastContainer />
      </div>
    );
  }
}

export default withLocalize(App);
