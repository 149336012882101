import { SET_QUESTIONNAIRE_SUCCESS, SET_QUESTIONNAIRE_ERROR, SET_QUESTIONNAIRE_DATA, SET_CLIENT_DATA,SET_QUESTIONNAIRE_LOADING,SET_QUESTIONNAIRE_COUNTDOWN_STATUS } from './constants';

const initialState = {
  questionnaireData: [],
  isQuestionnaireSuccess: false,
  questionnaireError: null,
  clientData: [],
  isLoading: true
};
export default function questionnaireReducer(state = initialState, action) {
  switch (action.type) {
   
    case SET_QUESTIONNAIRE_SUCCESS:

      return Object.assign({}, state, {
        isQuestionnaireSuccess: action.isQuestionnaireSuccess
      });

    case SET_QUESTIONNAIRE_ERROR:

      return Object.assign({}, state, {
        questionnaireError: action.questionnaireError
      });

    case SET_QUESTIONNAIRE_DATA:

      return {
        ...state,
        questionnaireData: action.questionnaireData
      }

    case SET_CLIENT_DATA:

      return {
        ...state,
        clientData: action.clientData
      }
    case SET_QUESTIONNAIRE_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading
      });
    case SET_QUESTIONNAIRE_COUNTDOWN_STATUS:
      return Object.assign({}, state, {
        countDownStatus: action.countDownStatus
      });

    default:
      return state;
  }
}