import React, { Component } from 'react';
import './AppLayout.css';

import Header from './Header';
import Footer from './Footer';

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header />
        <Footer />
      </div>
    );
  }
}

export default AppLayout;