import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import './Header.css';
// import Home from '../../Home';
// import Contact from '../../Contact';
import Questionnaire from '../../Questionnaire';
// import LanguageToggle from '../../LanguageToggle';
import NotFound from '../../NotFound';

class Header extends Component {
  
  render() {
    let baseUrl = "/";
    const { clinicName,clinicDescription,clinicLogo } = this.props;
    return (
      <div>
        <Router>
          <div className="App">

            <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
              <div className="container">
                <a href={baseUrl} className="navbar-brand">
                  {clinicLogo!=="" ? <img src={clinicLogo} width="45" alt="" className="d-inline-block align-middle mr-2" /> : '' }
                  <span className="app-title font-weight-bold"> {clinicName} <Translate id="app-title" /></span>
                </a>

              </div>
            </nav>
            <div className="container mt-4">
              <div className="mb-4"><strong>{clinicDescription}</strong></div>
              <Switch>
                <Route path="/:clientcode([A-Za-z0-9]{6}S6BCC|[A-Za-z0-9]{6}VEBHS|[A-Za-z0-9]{6}TALXZ)" component={Questionnaire} />
                <Route path="*" exact component={NotFound} />
              </Switch>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feedbackUrl: state.appLayoutReducer.feedbackUrl,
    clinicName: state.appLayoutReducer.name,
    clinicDescription: state.appLayoutReducer.description,
    clinicLogo: state.appLayoutReducer.logo
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);