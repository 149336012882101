import { SET_CLIENT_DATA, SET_API_URL, SET_FEEDBACK_URL, SET_CLINIC_NAME, SET_CLINIC_DESCRIPTION, SET_LOGO, SET_EDITED_STATUS, SET_DISABLE_ANSWER_TIME,SET_ANSWER_HOUR } from './constants';

const initialState = {
  clientData: {},
  apiUrl: "",
  feedbackUrl: ""
};

export default function appLayoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_API_URL:
      return Object.assign({}, state, {
        apiUrl: action.apiUrl
      });

    case SET_CLIENT_DATA:
      return Object.assign({}, state, {
        clientData: action.clientData
      });
    case SET_FEEDBACK_URL:
      return Object.assign({}, state, {
        feedbackUrl: action.feedbackUrl
      });
    case SET_CLINIC_NAME:
      return Object.assign({}, state, {
        name: action.name
      });
    case SET_CLINIC_DESCRIPTION:
      return Object.assign({}, state, {
        description: action.description
      });
    case SET_LOGO:
      return Object.assign({}, state, {
        logo: action.logo
      });
    case SET_EDITED_STATUS:
      return Object.assign({}, state, {
        editedStatus: action.status
      });
    case SET_DISABLE_ANSWER_TIME:
      return Object.assign({}, state, {
        time: action.time
      });
    case SET_ANSWER_HOUR:
      return Object.assign({}, state, {
        hour: action.hour
      });
  
    default:
      return state;
  }
}