import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
  // constructor(props){
  // super(props);
  // this.state = {};
  // }

  // componentWillMount(){}
  // componentDidMount(){}
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <footer className="bg-light py-2 mt-5 fixed-bottom">
        <div className="container">
          <div className="small text-center text-muted">Copyright © 2019 - <a href="https://www.validi.dk"> www.validi.dk</a></div>
        </div>
      </footer>
    );
  }
}

export default Footer;