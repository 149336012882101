import React from 'react';
import { SET_QUESTIONNAIRE_ERROR, SET_QUESTIONNAIRE_SUCCESS, SET_QUESTIONNAIRE_DATA, SET_QUESTIONNAIRE_LOADING, SET_QUESTIONNAIRE_COUNTDOWN_STATUS } from './constants';
import axios from 'axios';
import { withoutHeader } from '../../service/ApiConfig';
import * as webConst from '../../utils/WebConstant';
import { Translate } from "react-localize-redux";
import * as appLayoutAction from '../../Containers/AppLayoutContainer/action';

const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.timeout = withoutHeader.timeOut;

export function initialRequest(activationCode) {
    return dispatch => {
        dispatch(getUrlFromCode(activationCode));
    }
}

export function isLoadingStatus(isLoading) {
    return dispatch => {
        dispatch(setQuestionnaireLoading(isLoading));
    }
}

function getUrlFromCode(activationCode) {

    let serverCode = activationCode.replace(activationCode.substr(0, 6), '');

    var ApiUrl = webConst.REACT_APP_BASE_SERVER_PRODUCTION + '?action=getUrlFromCode&code=' + serverCode;
    return dispatch => {

        dispatch(setQuestionnaireLoading(true));
        dispatch(setQuestionnaireSuccess(false));
        dispatch(setQuestionnaireError(''));

        axios.get(ApiUrl)
            .then(function (response) {
                if (response.data.response_status === true) {
                    withoutHeader.params = response.data.response_body;
                    axiosDefaults.baseURL = response.data.response_body + '/validi-app-api-v1/';
                    //axiosDefaults.baseURL = 'http://192.168.0.5/validi/validi-app-api-v1/';
                    dispatch(checkActivationCode(activationCode));
                    dispatch(appLayoutAction.setApiUrl(axiosDefaults.baseURL));
                }
                else {
                    dispatch(setQuestionnaireError({ 'message': 'alert-message.something-wrong' }));
                    dispatch(setQuestionnaireLoading(false));
                    webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong"></Translate>);
                }

            })
            .catch(function (error) {
                dispatch(setQuestionnaireError({ 'message': 'alert-message.something-wrong' }));
                dispatch(setQuestionnaireLoading(false));
                webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong"></Translate>);
                console.log("error", error);
            })
    }
}

function checkActivationCode(activationCode) {

    return dispatch => {

        dispatch(setQuestionnaireLoading(true));
        dispatch(setQuestionnaireSuccess(false));
        dispatch(setQuestionnaireError(''));

        var ApiUrl = webConst.CLIENT_CODE_VALIDATE + '?activation_code=' + activationCode;

        axios.get(ApiUrl)
            .then(function (response) {
                if (response.data.status === 200) {
                    let data = {
                        activationCode: activationCode,
                        clientId: response.data.client_id,
                        clinicId: response.data.clinic_id
                    };
                    dispatch(appLayoutAction.setClientData(data));
                    dispatch(getQuestionanires(data));
                    localStorage.setItem("client-code", '/' + activationCode);
                    dispatch(appLayoutAction.setFeedbackUrl('/' + activationCode));
                    if (response.data.clinic_name)
                        dispatch(appLayoutAction.setClinicName(response.data.clinic_name));
                    if (response.data.clinic_description)
                        dispatch(appLayoutAction.setClinicDescription(response.data.clinic_description));
                    if (response.data.logo)
                        dispatch(appLayoutAction.setLogo(response.data.logo));

                    if (response.data.edited === "false" && response.data.disable_answer_time === "") {
                        dispatch(appLayoutAction.setEditedStatus(false));
                        dispatch(countDownStatus(false));
                    }
                    else if (response.data.edited === "true" && response.data.disable_answer_time !== "") {
                        dispatch(appLayoutAction.setEditedStatus(false));
                        dispatch(countDownStatus(true));
                    }
                    else if (response.data.edited === "true" && response.data.disable_answer_time === "") {
                        dispatch(appLayoutAction.setEditedStatus(true));
                        dispatch(countDownStatus(false));
                    }else {
                        dispatch(appLayoutAction.setEditedStatus(false));
                        dispatch(countDownStatus(false));
                    }
                    
                    let disable_answer_time="";
                    if (typeof response.data.disable_answer_time!==undefined && typeof response.data.disable_answer_time!=="undefined")
                        disable_answer_time=response.data.disable_answer_time;
                        
                    dispatch(appLayoutAction.setDisableAnswerTime(disable_answer_time));
                    
                    if (response.data.edit_answer_hour) {
                        dispatch(appLayoutAction.setEditAnswerHour(response.data.edit_answer_hour));
                    }

                }
                else {
                    dispatch(setQuestionnaireError({ "message": "alert-message.link-expired" }));
                    dispatch(setQuestionnaireLoading(false));
                    webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.link-expired"></Translate>);
                }
            })
            .catch(function (error) {
                dispatch(setQuestionnaireError({ "message": "alert-message.something-wrong" }));
                dispatch(setQuestionnaireLoading(false));
                webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong"></Translate>);
                console.log("error", error);
            })
    }
}

function getQuestionanires(data) {

    let clinicId = typeof data.clinicId != 'undefined' ? data.clinicId : 0;
    let clientId = typeof data.clientId != 'undefined' ? data.clientId : 0;
    var ApiUrl = webConst.GET_QUESTIONS + '?client_id=' + clientId + '&clinic_id=' + clinicId;

    return dispatch => {

        dispatch(setQuestionnaireLoading(true));
        dispatch(setQuestionnaireSuccess(false));
        dispatch(setQuestionnaireError(''));

        axios.get(ApiUrl)
            .then(function (response) {
                let questionnaires = Object.create({});
                response.data.questions.forEach((item, index) => {

                    item.qst_ans = item.qst_ans ? item.qst_ans : '';
                    item.client_id = clientId;
                    questionnaires[item.qst_id] = item;
                })
                dispatch(setQuestionnaireData(questionnaires));
                dispatch(setQuestionnaireSuccess(true));
                dispatch(setQuestionnaireLoading(false));
            })
            .catch(function (error) {
                dispatch(setQuestionnaireError({ "message": "alert-message.link-expired" }));
                dispatch(setQuestionnaireLoading(false));
                webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong"></Translate>);
                console.log("error", error);
            })
    }
}

export function saveAnswerRequest(data, clientId) {

    return dispatch => {

        dispatch(setQuestionnaireLoading(true));
        dispatch(setQuestionnaireSuccess(false));
        dispatch(setQuestionnaireError(''));

        var ApiUrl = webConst.SAVE_ANSWER;

        const params = new URLSearchParams();
        params.append('answers', JSON.stringify(data));
        params.append('client_id', clientId);

            axios({
                method: 'post',
                url: ApiUrl,
                data: params,
                dataType: 'json'
            })
            .then(function (response) {

                dispatch(setQuestionnaireLoading(false));
                dispatch(setQuestionnaireSuccess(true));
                dispatch(updateCountDownStatus(true));
                // dispatch(appLayoutAction.setEditedStatus(false));
                webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="alert-message.save_answer"></Translate>);
            })
            .catch(function (error) {
                dispatch(setQuestionnaireError({ "message": "alert-message.something-wrong" }));
                // dispatch(setQuestionnaireLoading(false));
                webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong"></Translate>);
                console.log("error", error);
            });
    }
}


function setQuestionnaireData(questionnaireData) {
    return {
        type: SET_QUESTIONNAIRE_DATA,
        questionnaireData
    };
}

function setQuestionnaireSuccess(isQuestionnaireSuccess) {
    return {
        type: SET_QUESTIONNAIRE_SUCCESS,
        isQuestionnaireSuccess
    };
}

function setQuestionnaireError(questionnaireError) {
    return {
        type: SET_QUESTIONNAIRE_ERROR,
        questionnaireError
    }
}


function setQuestionnaireLoading(isLoading) {
    return {
        type: SET_QUESTIONNAIRE_LOADING,
        isLoading
    }
}

function countDownStatus(countDownStatus) {
    return {
        type: SET_QUESTIONNAIRE_COUNTDOWN_STATUS,
        countDownStatus
    }
}
export function updateCountDownStatus(status) {
    return dispatch => {
        if (status) {
            dispatch(appLayoutAction.setEditedStatus(false));
            dispatch(countDownStatus(true));
        }
        else {
            dispatch(appLayoutAction.setEditedStatus(true));
            dispatch(countDownStatus(false));
        }
    }
}
